import React from 'react'
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { v4 as uuidv4 } from "uuid"
import Item from "../Item"
import ContextConsumer from "../../context/LanguageContext"
import colours from "../../config/colours"

const FeedItem = styled.div`
  margin: 0 0 2rem 0;
  border-bottom: 2px solid rgba(${colours.antiqueWhite});
  
  &:last-child {
    border: none;
  }
`

const Index = () => {
  const craftData = useStaticQuery(graphql`
    query FeedQuery {
      craftapi {
        entryEN: entries (siteId: "1" type: "news") {
          ... on CraftAPI_news_news_Entry {
            postDate
            title
            articleIntro
            uri
            featuredImage {
              src: url (transform:"desktop")
              srcThumbnail: url (transform:"thumbnail")
              focalPoint
              title
            }
          }
        }
        entryCY: entries (siteId: "2" type: "news") {
          ... on CraftAPI_news_news_Entry {
            postDate
            title
            articleIntro
            uri
            featuredImage {
              src: url (transform:"desktop")
              srcThumbnail: url (transform:"thumbnail")
              focalPoint
              title
            }
          }
        }
      }
    }
  `)

  const {
    craftapi: {
      entryEN : en,
      entryCY: cy,
    }
  } = craftData

  return (
    <ContextConsumer>
      {({ data }) => (
        <>
          {(data.currentLanguage === 'en') && (
            en
              .sort((a, b) => new Date(b.postDate) - new Date(a.postDate))
              .map(entry => (
              <FeedItem key={uuidv4()}>
                <Item
                  key={uuidv4()}
                  itemName={entry.title}
                  itemInformation={entry.articleIntro}
                  itemImage={entry.featuredImage}
                  itemMore={entry.uri}
                  itemMoreText={`Read Article`}
                />
              </FeedItem>
            ))
          )}
          {(data.currentLanguage === 'cy') && (
            cy.map(entry => (
              <FeedItem>
                <Item
                  key={uuidv4()}
                  itemName={entry.title}
                  itemInformation={entry.articleIntro}
                  itemImage={entry.featuredImage}
                  itemMore={entry.uri}
                  itemMoreText={`Read Article`}
                />
              </FeedItem>
            ))
          )}
        </>
      )}
    </ContextConsumer>
  )
}

export default Index