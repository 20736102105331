import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Main from "../layouts/main"
import Feed from '../components/Feed'
import Header from "../layouts/header"
import SEO from "../components/seo"
import Editorial from "../components/Fields/Editorial"
import Section from "../layouts/section"
import ContextConsumer from "../context/LanguageContext"
import { v4 as uuidv4 } from "uuid"

const Page = props => {
  const craftData = useStaticQuery(graphql`
    query PageEntriesQuery {
      craftapi {
        entriesEN: entries (type: "standardPage" siteId: "1") {
          ... on CraftAPI_pages_standardPage_Entry {
            newsFeed
            id
            title
            editorial {
              ...on CraftAPI_editorial_sectionTitle_BlockType {
                titleText
                typeHandle
              }
              ... on CraftAPI_editorial_singleImage_BlockType {
                image {
                  src: url(transform: "landscape")
                }
                layout
                typeHandle
              }
              ...on CraftAPI_editorial_text_BlockType {
                copy
                typeHandle
              }
              ... on CraftAPI_editorial_textAndImage_BlockType {
                copy
                image {
                  srcMobile: url(transform: "mobile")
                  srcTablet: url(transform: "tablet")
                  srcDesktop: url(transform: "desktop")
                }
                position
                typeHandle
              }
              ... on CraftAPI_editorial_gallery_BlockType {
                sectionIntro
                lightboxMode
                images {
                  id
                  srcLandscape: url(transform: "landscape")
                  srcThumbnail: url(transform: "thumbnail")
                  title
                  focalPoint
                }
                typeHandle
              }
              ... on CraftAPI_editorial_item_BlockType {
                itemName
                itemInformation
                itemImage {
                  srcThumbnail: url(transform: "thumbnail")
                }
                typeHandle
              }
              ... on CraftAPI_editorial_textAndEmbed_BlockType {
                copy
                embed
                typeHandle
              }
            }
          }
        }
        entriesCY: entries (type: "standardPage" siteId: "2") {
          ... on CraftAPI_pages_standardPage_Entry {
            newsFeed
            id
            title
            editorial {
              ...on CraftAPI_editorial_sectionTitle_BlockType {
                titleText
                typeHandle
              }
              ... on CraftAPI_editorial_singleImage_BlockType {
                image {
                  src: url(transform: "landscape")
                }
                layout
                typeHandle
              }
              ...on CraftAPI_editorial_text_BlockType {
                copy
                typeHandle
              }
              ... on CraftAPI_editorial_textAndImage_BlockType {
                copy
                image {
                  srcMobile: url(transform: "mobile")
                  srcTablet: url(transform: "tablet")
                  srcDesktop: url(transform: "desktop")
                }
                position
                typeHandle
              }
              ... on CraftAPI_editorial_gallery_BlockType {
                sectionIntro
                lightboxMode
                images {
                  id
                  srcLandscape: url(transform: "landscape")
                  srcThumbnail: url(transform: "thumbnail")
                  title
                  focalPoint
                }
                typeHandle
              }
              ... on CraftAPI_editorial_item_BlockType {
                itemName
                itemInformation
                itemImage {
                  srcThumbnail: url(transform: "thumbnail")
                }
                typeHandle
              }
              ... on CraftAPI_editorial_textAndEmbed_BlockType {
                copy
                embed
                typeHandle
              }
            }
          }
        }
      }
    }
  `)

  const {
    pageContext: {
      id
    }
  } = props

  const {
    craftapi: {
      entriesEN,
      entriesCY,
    }
  } = craftData

  // match the page slug to the uri passed in by the createPage context (see gatsby-node.js)
  const en = entriesEN.filter(entry => entry.id === id)[0] || {}
  const cy = entriesCY.filter(entry => entry.id === id)[0] || {}

  return (
    <ContextConsumer>
      {({ data }) => (
        <React.Fragment key={uuidv4()}>
          {(data.currentLanguage === 'en') && (
            <>
              <SEO title={en.title} />
              <Main margin="2rem 0">
                <Section padding="2rem 2rem 0.5rem 2rem">
                  <Header level={1} margin={`0 0 2rem 0`} centre>{en.title}</Header>
                </Section>
                <Editorial data={en.editorial}/>
                {en.newsFeed && (
                  <Section margin={`2rem auto 0 auto`}>
                    <Feed />
                  </Section>
                )}
              </Main>
            </>
          )}
          {(data.currentLanguage === 'cy') && (
            <>
              <SEO title={cy.title} />
              <Main margin="2rem 0">
                <Section padding="2rem 2rem 0.5rem 2rem">
                  <Header level={1} margin={`0 0 2rem 0`} centre>{cy.title}</Header>
                </Section>
                <Editorial data={cy.editorial}/>
                {en.newsFeed && (
                  <Section margin={`2rem auto 0 auto`}>
                    <Feed />
                  </Section>
                )}
              </Main>
            </>
          )}
        </React.Fragment>
      )}
    </ContextConsumer>
  )
}

export default Page
